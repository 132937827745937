<cc-table
  [ccSearchFormControl]="queryFormControl"
  [ccSearchable]="true"
  [ccStickyHeader]="true"
  ccPadding="medium"
  ccSearchPlaceholder="Search workflows..."
  [ccEmptyRowTemplate]="emptyRowTemplate"
>
  <ng-container slot="cc-table-title">
    @if (workflowsCount$ | async; as workflowsCount) {
      {{ workflowsCount }} {{ workflowsCount === 1 ? 'workflow' : 'workflows' }}
    }
  </ng-container>

  <ng-container slot="сс-table-custom-actions">
    <cc-select [formControl]="statusSelectFormControl">
      <ng-container ccOptions>
        @for (option of statusSelectItems; track option.id) {
          <cc-select-item
            [id]="option.id"
            [value]="option.title"
            [payload]="option.payload"
          />
        }
      </ng-container>
    </cc-select>
  </ng-container>

  <ng-container slot="cc-table">
    <thead>
      <tr ccTr>
        <th
          ccTh
          (ccSortChange)="changeSort('name', $event)"
          [ccSortDirection]="nameSortingOrder$ | async"
          [ccSortable]="true"
        >
          Workflow
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('type', $event)"
          [ccSortDirection]="typeSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="184px"
        >
          Type
        </th>

        <th
          ccTh
          (ccSortChange)="changeSort('status', $event)"
          [ccSortDirection]="statusSortingOrder$ | async"
          [ccSortable]="true"
          ccWidth="184px"
        >
          Status
        </th>

        <th
          ccTh
          ccWidth="48px"
        ></th>
      </tr>
    </thead>

    <tbody>
      @for (workflow of workflows$ | async; track workflow.id) {
        <tr ccTr>
          <cc-workflow-table-row [workflow]="workflow" />
        </tr>
      }
    </tbody>
  </ng-container>
</cc-table>

<ng-template #emptyRowTemplate> There are no workflows to display. </ng-template>
