import { CdkScrollable } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { NgScrollbar } from 'ngx-scrollbar';

import { WorkflowsHeaderComponent } from './workflows-header/workflows-header.component';
import { WorkflowsTableComponent } from './workflows-table/workflows-table.component';
import { LoadNextWorkflows } from '../state/workflows.actions';

@Component({
  selector: 'cc-workflows-list',
  standalone: true,
  imports: [NgScrollbar, InfiniteScrollDirective, CdkScrollable, WorkflowsHeaderComponent, WorkflowsTableComponent],
  templateUrl: './workflows-list.component.html',
  styleUrl: './workflows-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsListComponent {
  private readonly store = inject(Store);

  loadNextWorkflows(): void {
    this.store.dispatch(new LoadNextWorkflows());
  }
}
