<div class="workflows-list">
  <ng-scrollbar
    (scrolled)="loadNextWorkflows()"
    [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="0"
    [infiniteScrollUpDistance]="4"
    [scrollWindow]="false"
    cdkScrollable
    infiniteScroll
    class="workflows-list__list"
  >
    <div class="workflows-list__list-wrapper">
      <cc-workflows-header class="workflows-list__list-wrapper-header" />
      <cc-workflows-table class="workflows-list__list-wrapper-list" />
    </div>
  </ng-scrollbar>
</div>
