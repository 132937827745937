import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { PageWithSubnavComponent } from '../../stories/layout/page-with-subnav/page-with-subnav.component';

@Component({
  selector: 'cc-workflows-page',
  standalone: true,
  imports: [PageWithSubnavComponent, RouterOutlet],
  templateUrl: './workflows-page.component.html',
  styleUrl: './workflows-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsPageComponent {}
