import { inject, Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import * as R from 'ramda';
import { catchError, map, of, tap, type Observable } from 'rxjs';

import { CdkPortalService } from '@clover/core/services/cdk-portal.service';

import { defaultWorkflowsState, type WorkflowsStateModel } from './workflows-state.model';
import { LoadNextWorkflows, LoadWorkflows, PatchWorkflow, RemoveWorkflow } from './workflows.actions';
import { WorkflowsService } from './workflows.service';

@State<WorkflowsStateModel>({
  name: 'workflows',
  defaults: defaultWorkflowsState,
})
@Injectable()
export class WorkflowsState {
  private readonly workflowsService = inject(WorkflowsService);
  private readonly portalService = inject(CdkPortalService);

  @Action(LoadWorkflows, { cancelUncompleted: true })
  loadWorkflows(ctx: StateContext<WorkflowsStateModel>, { payload }: LoadWorkflows): Observable<void> {
    const state = ctx.getState();
    const query = R.isNil(payload.query) ? state.query : payload.query;
    const status = R.isNil(payload.status) ? state.status : payload.status;
    const sorting = payload.sortingOptions || state.sorting;

    ctx.patchState({ loadingStatus: 'loading' });

    return this.workflowsService
      .searchWorkflows(
        query,
        { status },
        {
          offset: 0,
          orderBy: sorting.orderBy,
          order: sorting.order,
          limit: 50,
        },
      )
      .pipe(
        tap((workflows) => {
          ctx.patchState({ workflows, query, sorting, loadingStatus: 'loaded' });
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(LoadNextWorkflows, { cancelUncompleted: true })
  loadNextWorkflows(ctx: StateContext<WorkflowsStateModel>): Observable<void> {
    const state = ctx.getState();
    const offset = state.workflows.count;
    const query = state.query;
    const status = state.status;
    const sortingOptions = state.sorting;

    ctx.patchState({ loadingStatus: 'loading-next' });

    return this.workflowsService
      .searchWorkflows(
        query,
        { status },
        {
          offset,
          orderBy: sortingOptions.orderBy,
          order: sortingOptions.order,
          limit: 50,
        },
      )
      .pipe(
        tap((nextWorkflows) => {
          ctx.setState(
            patch<WorkflowsStateModel>({
              workflows: patch({
                data: append(nextWorkflows.data),
                count: state.workflows.count + nextWorkflows.count,
                paging: nextWorkflows.paging,
              }),
              loadingStatus: 'loaded',
            }),
          );
        }),
        catchError(() => {
          ctx.patchState({ loadingStatus: 'error' });
          return of(undefined);
        }),
        map(() => undefined),
      );
  }

  @Action(PatchWorkflow)
  patchWorkflow(ctx: StateContext<WorkflowsStateModel>, { workflowId, patch: patchedValue }: PatchWorkflow): void {
    ctx.setState(
      patch<WorkflowsStateModel>({
        workflows: patch({
          data: updateItem((workflow) => workflow.id === workflowId, patchedValue),
        }),
      }),
    );
  }

  @Action(RemoveWorkflow)
  removeWorkflow(ctx: StateContext<WorkflowsStateModel>, { workflowId }: RemoveWorkflow): void {
    ctx.setState(
      patch<WorkflowsStateModel>({
        workflows: patch({
          data: removeItem((workflow) => workflow.id === workflowId),
          count: (count) => count - 1,
          total: (total) => total - 1,
        }),
      }),
    );
  }
}
