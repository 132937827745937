<header class="workflows-header">
  <div class="workflows-header__details">
    <h2 class="workflows-header__details-title">Workflows</h2>

    <p class="workflows-header__details-description">
      Workflows allow you to model and standardize your organization's processes to automate requests for information
      from your trading partners using standard company profile attributes or custom attributes that your organization
      defines using custom fields.
    </p>
  </div>

  <div class="workflows-header__actions">
    <div #dropdownTrigger>
      <app-button
        (click)="dropdownVisible = !dropdownVisible"
        class="workflows-header__actions-new-workflow"
        [class.workflows-header__actions-new-workflow--expanded]="dropdownVisible"
        [type]="ButtonType.Primary"
        [size]="ButtonSize.Small"
      >
        New workflow

        <i
          class="workflows-header__actions-new-workflow-icon icon-chevronDown"
          slot="end-icon"
        ></i>
      </app-button>
    </div>
  </div>
</header>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown width="200px">
    <cc-dropdown-text>
      <ng-container slot="title"> Workflow type </ng-container>
    </cc-dropdown-text>

    <cc-dropdown-action (click)="createNewWorkflow(WorkflowType.Company); dropdownVisible = false">
      <ng-container slot="title"> Company workflow </ng-container>
    </cc-dropdown-action>

    <cc-dropdown-action (click)="createNewWorkflow(WorkflowType.Product); dropdownVisible = false">
      <ng-container slot="title"> Product workflow </ng-container>
    </cc-dropdown-action>
  </cc-dropdown>
</ng-template>
